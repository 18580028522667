import React from 'react';
import { BlogPost } from 'views/blog-post';
import { Overlay } from 'components/overlay';
import { object } from 'prop-types';

import { usePreviewBlogPostData } from './hooks';

const previewSecretKey = process.env.GATSBY_PREVIEW_SECRET_KEY;
export const Preview = ({ location }) => {
    const urlParams = new URLSearchParams(location.search);
    const slug = urlParams.get('slug');
    const secret = urlParams.get('secret');
    const allowAccess = slug && secret === previewSecretKey;

    const { data } = usePreviewBlogPostData({
        slug,
        allowAccess,
    });

    return (
        <>
            {data ? (
                <BlogPost blogPost={data} isPreviewMode />
            ) : (
                <Overlay show />
            )}
        </>
    );
};

Preview.propTypes = {
    location: object.isRequired,
};
